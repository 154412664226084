<template>
  <section class="typical-page-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8">
          <h1 class="typical-title">Terms and Conditions</h1>

          <div class="typical-content">
            <h2>Resume-Perfect.com</h2>
            <h2>Effective Date: May 2022</h2>
            <h2>Welcome to resume-perfect.com!</h2>
            <p>
              Before you start, take a few minutes to review the terms and
              conditions of your Site access and use. These Terms and conditions
              of Use (hereinafter "Terms") govern your use of this Site, and any
              other Sites that link to these Terms, as well as other related
              mobile websites, services, tools, and other applications.
            </p>
            <p>
              The full terms are set forth below. All are important, but one of
              the most important is that each time you access and/or use the
              Site, you agree to be bound by these Terms, and any additional or
              modified terms that are posted. Those new terms will apply
              thereafter to you.
            </p>

            <h2>Full Terms and Conditions of Your Site Use</h2>
            <h3>1. Your Acceptance of These Terms</h3>
            <p>
              If you do not agree with any of these Terms and Conditions of your
              Site use (“Terms”), including the Privacy Policy incorporated
              herein, you are not authorized to use this Site and please cease
              all use at this time. By continuing to use this Site now and in
              the future, you will be deemed to have irrevocably agreed to these
              Terms and any modifications posted. Some Site areas may be subject
              to different or additional terms, which you should carefully
              review before using those areas. Those additional terms will not
              change or replace these Terms regarding use of this Site, unless
              otherwise expressly stated.
            </p>
            <p>
              - Users who are minors where they reside (generally people under
              the age of 18 in most US states) must have the permission of their
              parent or guardian to use this Site after the parent or guardian
              reads and agrees to these terms before use. Use of this Site by a
              minor must also be supervised by their parent or guardian. If you
              are under the age of 18 in most US states, you should check with
              your parent or guardian before proceeding. Persons under age 13
              may not use this Site or register for its services.
            </p>
            <p>
              - These Terms may be revised and reissued from time to time, by
              posting updated or modified terms on this Site. Your use of the
              Site binds you to all modified and updated Terms. You consent to
              receive notice of these modifications and updates by our posting
              of them on the Site. You should visit this page to review the
              current Terms. Your continued use of the Site is deemed
              irrevocable acceptance of any revisions to Terms. If you do not
              agree to a modified or revised Term that has been posted, it is up
              to you to notify us in writing that you disagree, and to request
              immediate suspension or termination of your account.
            </p>
            <p>
              - resume-perfect.com makes no representation that materials or
              other content on the Site are appropriate or available for use
              outside of the United States. If you choose to access the Site
              from other locations you do so on your own initiative and at your
              own risk.
            </p>
            <p>
              - You are responsible for complying with your local laws, if and
              to the extent local laws are applicable. You agree to comply with
              all applicable laws concerning the transmission or export of
              technical data from the United States, or your country of
              residence.
            </p>

            <h3>2. User Representations to Us</h3>

            <p>You are expected to use this Site for its intended purpose.</p>

            <p>
              Therefore, it is a continuing condition of your Site access and
              use that all the information, images, materials and data (referred
              to collectively herein as “Data”) that you provide to us: (a), is
              and will continue to be, accurate and complete; (b) is Data you
              are authorized to provide to us, and to post, and not in breach or
              violation of any law, rule, regulation, order or agreement of
              non-disclosure or confidentiality; (c) does not seek to
              impersonate any person, misrepresent a fact or thing, including
              your affiliation with any person or entity; (d) does not seek to
              ridicule a person or entity, or glorify or incite violence,
              illegal or inappropriate conduct, or call for the overthrow of any
              government; (e), is a bona fide use of this Site for its intended
              purpose, and not for some other purpose or to impair, damage,
              harass or annoy this Site, its functionality or any other person
              or entity; (f), is not intended to and does not libel, slander, or
              defame a person, or violate a person’s privacy or publicity
              rights, and is not intended to and does not contain or constitute
              racist, offensive, abusive, obscene, pornographic, violent or
              harassing content as to any person, entity or organization; (g),
              does not contain or constitute an infringement on the intellectual
              property, trademarks or service marks of others; (h), does not
              ridicule or parody another or depict any other person in a
              negative way,(i) does not misrepresent your affiliation or
              employment with any person or organization, or your experience
              with any organization or its officers, directors, employees or
              vendors or agents; (j), does not include any harmful or
              unauthorized software, spyware, malware, viruses, worms, trojan
              horses, code or other harmful or destructive devices or code
              designed to impair, or which do interfere with or impair, in our
              judgment, the normal and intended operations of this Site, the
              experience of other Users, or which links to other sites, seeks to
              store Data which we determine is excessive in size as we determine
              in or sole discretion, or another abuse of this site for file
              storage purposes; (k), does not constitute trolling to generate
              responses from others, or solicit responses or funds from others
              for purposes unrelated to this site, including but not related to
              political activity, legal disputes, or harassment; (l) shall not
              seek to modify, copy, reproduce, republish, upload, post,
              distribute or used our Site data and material in any way unless
              specifically authorized in writing by resume-perfect.com; (m),
              will not result in modification, decompiling, reverse engineering,
              disassembling, or other tampering with or reducing the code used
              in Site software to any reviewable form to examine it and/or to
              reproduce, copy or create other products; (n), all Data and use
              will fully comply with laws regulating marketing , advertising,
              security and privacy, including the U.S. Telemarketing and
              Consumer Fraud and Abuse Prevention Act and the CAN-SPAM Act of
              2003. If we believe the Data you provide or the use you make of
              the Site violates any of the Terms set forth above, including your
              warranties to us, or violates the Terms set forth elsewhere
              herein, we may refuse you Site access, terminate or suspend your
              account or otherwise act as provided in these Terms. Said action
              may be taken in our sole discretion, and with or without notice,
              and without liability for costs, fees or damages. You also agree
              that in the event that an assertion is made to us that Data you
              have posted is illegal, confidential, harassing or unauthorized,
              that you will remove it immediately upon our request, and until a
              resolution is reached that is agreeable to us. You agree that the
              Data shall not be reposted or resubmitted until we notify you that
              you may do so. You will have the burden of proving to us that it
              is not unauthorized or objectionable and our decision shall be in
              or sole discretion and final and binding on all parties to the
              dispute. Your sole remedy for any such dispute shall be the
              cancellation of your account
            </p>
            <p>
              You further warrant to us and agree that you understand and agree
              that: (a) your understanding and agreement that comments made and
              Data posted on bulletin boards, chat rooms and other locations on
              this site or elsewhere by other Users are not monitored by us, and
              they do not necessarily represent the positions of
              resume-perfect.com or our content providers. But that content is
              not our responsibility, and we have no obligation to edit or
              remove Data or content with which you disagree, or that you find
              offensive for any reason or reasons. Use of comment rooms,
              bulletin boards etc., are at the User’s sole risk, and User
              assumes the risk of offensive, demeaning, hurtful, annoying,
              disparaging remarks and other remarks; and, while we may from time
              to time remove or edit such remarks or postings, including
              photographs, we have no duty to do so and no duty arises to so,
              merely because we may elect to do so from time to time in or sole
              discretion; (b), that we are not perfect and that products or
              services offered from time to time, or featured from time to time,
              may on occasion be described in a manner that is erroneous
              including as to features or pricing: in such event, you agree that
              we shall have the right to refuse or cancel any order without
              liability for fees, costs, or damages claimed of any kind. If we
              charged your credit card or other account prior to cancellation,
              we will credit your account in the amount of the charge. No such
              credit shall issue if the cancellation was submitted after the
              charge was made. Additional terms and conditions may apply.
            </p>
            <h3>3. Online Privacy Notice</h3>
            <p>
              Our online information procedures and the choices you can elect
              about the way your information is collected and used has been
              provided for your careful review. Click on the link at the end of
              this paragraph to show this Privacy notice, which constitutes part
              of these Terms. <a href="#">(Link to Privacy Policy here)</a>.
            </p>

            <h3>4. Disclaimer of Warranties: Users Use at Own Risk</h3>

            <p>
              Because many individuals are litigious or expect others to modify
              their conduct to suit the most sensitive, we are impelled to
              advise each User or prospective User of this Site that YOUR USE OF
              THIS SITE IS AT YOUR OWN RISK. The site and all Data, photographs,
              images, displays, materials and other information, including the
              software, facilities services and all other site content are
              depicted, provided, displayed and provided for use and enjoyment
              “AS IS,” and “AS AVAILABLE," without warranties of any kinds,
              whether express or implied. All warranties of any kind are
              disclaimed to the fullest extent allowed by law as stated here:
            </p>

            <p>
              resume-perfect.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
              NON-INFRINGEMENT.
            </p>

            <p>
              resume-perfect.com DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED
              IN THIS SITE AND OR OFFERED IN THIS SITE WILL BE ALWAYS AVAILABLE,
              UNINTERRUPTED OR ERROR-FREE DURING YOUR USE OR AT TIMES WHEN YOU
              DESIRE TO USE THE SITE, THAT ANY SITE DEFECTS WILL BE DETECTED OR
              CORRECTED IN TIME FOR YOUR DESIRED USE OR TO ENABLE COMPLETION OF
              A DESIRED USE, OR THAT THE SITE OR THE SERVERS THAT MAKE THE SITE
              AVAILABLE, INCLUDING NON-PARTY SITES TO WHICH LINKS ARE PROVIDED
              ARE FREE OF SPYWARE, IMPAIRING CODE, VIRUSES, MALWARE OR OTHER
              HARMFUL COMPONENTS.
            </p>

            <p>
              resume-perfect.com DOES NOT IN ANY WAY, WARRANT OR REPRESENT
              REGARDING THE USE OR THE RESULTS OF THE USE OF THE DISPLAYS,
              PHOTOGRAPHS, DATA, MATERIAL, INFORMATION, SOFTWARE, FACILITIES,
              SERVICES OR OTHER CONTENT ON THE SITE OR ANY WEBSITES LINKED TO
              THE SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR
              OTHERWISE.
            </p>

            <p>
              resume-perfect.com MAKES NO WARRANTIES THAT YOUR USE OF THE
              MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICE OR OTHER
              CONTENT IN THE SITE OR ANY WEBSITE WILL NOT INFRINGE THE RIGHTS OF
              OTHERS ELECTING TO ASSERT SUCH RIGHTS, WHETHER THEIR ASSERTIONS
              ARE RIGHTFUL OR ERRONEOUS, AND resume-perfect.com ASSUMES NO
              LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH
              MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICE OR OTHER
              CONTENT OF THE SITE OR ANY OTHER WEBSITE.
            </p>

            <p>
              If applicable law does not permit the disclaimer of warranties and
              some or all of the disclaimed warranties exist in favor of a User
              despite this disclaimer, then the disclaimed warranties apply to
              you only to the extent permitted by law and only in circumstances
              permitted by law.
            </p>

            <p>
              Users accessing this Site from the European Union or other
              jurisdictions that do not recognize a disclaimer of certain
              warranties are advised that nothing in this agreement shall
              exclude or limit liability for death or personal injury resulting
              from the negligence of either party hereto, of their servants,
              agents or employees.
            </p>

            <h3>5. Limitation of Our Liability</h3>

            <p>
              resume-perfect.com DISCLAIMS ALL LIABILITY WHETHER BASED IN
              CONTRACT, TORT (INCLUDING CLAIMS OF GROSS NEGLIGENCE, AND
              NEGLIGENCE), STRICT LIABILITY, IMPLIED WARRANTY OF FITNESS FOR A
              PARTICULAR PURPOSE, OR OTHERWISE, AND DISCLAIMS ALL LIABILITY FOR
              ANY LOSSES OR DAMAGES (DIRECT, INDIRECT, PUNITIVE, ACTUAL,
              CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY OR OTHERWISE)
              ALLEGEDLY RESULTING OR RESULTING FROM ANY USE OF, OR INABILITY TO
              USE, THE SITE OR ANY OTHER WEBSITE, OR THE MATERIAL, INFORMATION,
              SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON THE SITE OR ANY
              OTHER WEBSITE, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS
              CLAIMED, AND EVEN resume-perfect.com HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH LOSS OR DAMAGE.
            </p>

            <p>
              WITHOUT LIMITATION, YOU (AND NOT resume-perfect.com) ASSUME THE
              ENTIRE FEES AND COSTS OF ALL NECESSARY SERVICING, REPAIR, REMEDIAL
              ACTION OR CORRECTION IN THE EVENT OF ANY SUCH LOSS OR DAMAGE
              ARISING. IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE
              ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS
              WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            </p>

            <h3>6. Disputes and Resolution of Disputes with Us</h3>

            <p>
              (a) All Users: Prior to filing or submitting any claim, lawsuit or
              proceeding, or demand to any authority for corrective action,
              damages or other relief, you agree that you will first seek to
              resolve any dispute with us, by notifying us of your dispute in
              writing sent by email to . Your notice will be a good faith effort
              to provide us with details of the dispute so we know what you are
              disputing or demanding and why. When we receive your notice of
              dispute, we shall have 45 days to seek a resolution between us on
              the disputed matter. A resolution may include but is not limited
              to a credit to your account last on file with us, or a dispute of
              your claim. A request or details or further information inside the
              45-day window is not a resolution. You agree to cooperate in
              seeking a resolution inside the 45-day window and that may include
              proof of a payment you claim. No party shall be entitled to fees
              or costs of seeking to resolve the dispute as provided in this
              period. Our efforts to resolve the dispute with you shall not be
              deemed a waiver of any Terms herein.
            </p>

            <p>
              (b) Residents of the EU: If any controversy, allegation, or claim
              (including any non-contractual claim) arises out of or relates to
              the Site or Terms of Service, then you and we agree to send a
              written notice to the other providing a reasonable description of
              the Section 6(b) Dispute, along with a proposed resolution of it.
              Our notice to you will be sent to you based on the most recent
              contact information that you provide us. But if no such
              information exists or if such information is not current, then we
              have no obligation under this Section 6 (b). Your notice to us
              must be sent to us at . For a period of sixty (60) days from the
              date of receipt of notice from the other party, resume-perfect.com
              and you will engage in a dialogue in order to attempt to resolve
              the Section 6 (b) Dispute, though nothing will require either you
              or resume-perfect.com to resolve the Section 6(b) Dispute on terms
              either you or resume-perfect.com, in each of our sole discretion,
              are uncomfortable. Nothing in this Section will prevent a party
              from pursuing their claims in Court or another complaint process
              if the provisions herein conflict in part with any applicable law.
            </p>

            <h3>7. Residents of Other Locations:</h3>

            <p>
              Users of this Site from jurisdictions other than the United States
              shall comply with the re-dispute resolution procedure set forth
              above prior to filing any claim, lawsuit or demand, unless that
              procedure is prohibited by the country in which you reside. If the
              matter is not resolved, then you agree to arbitrate as provided in
              (b) above
            </p>

            <h3>
              8. Protection of Intellectual Property, Trademarks and Copyrights
            </h3>

            <p>
              This Site and materials incorporated by resume-perfect.com hereon
              ("Material") are protected by applicable trademarks, copyrights,
              patents, trade secret laws and other proprietary rights
              (collectively, "Copyrights"). Some of the images incorporated by
              resume-perfect.com on this Site with permission or authority are
              or may be also protected as registered or unregistered copyrights
              trademarks, trade names and/or service marks owned by
              resume-perfect.com or others ("Trademarks").
            </p>

            <h3>9. User Content, and Unsolicited or Requested Submissions.</h3>

            <p>
              Images, compositions, compilations, layouts, formats, ideas,
              concepts, and all materials and data (“Data”) submitted to this
              Site from you or any other source, any source, whether solicited
              or not, are governed by the terms in this section. Regardless of
              any subjective expectation of the submitter, or any local or other
              or industry custom or practice, you and all persons or entities
              submitting such matters agree that: (a) we will not compensate you
              or anyone affiliated with you for the receipt, evaluation,
              assessment or use of any such Data that you submit to the Site;
              (b) the Data you send to us in response to any request for
              submissions and Data cannot and will not be treated as
              confidential for any purpose, irrespective of a request that we do
              so or any oral representation allegedly made by us or on our
              behalf; (c) we do not warrant that any such Data will be
              continuously stored, cached or archived or that it will be
              retrievable by you at any later date, and you release us from any
              responsibility to store, cache, archive or maintain such Data for
              any length of time; (d), you and any other submitter of Data to
              this Site automatically grant us, and you represent that you have
              the authority to grant to us, a non-exclusive, royalty-free,
              fully-paid, fully transferable, sublicensable, worldwide right and
              license to host, copy, use, reproduce, broadcast, publish, store,
              archive, cache, publicly perform, display, exploit translate,
              format and reformat, transmit, and distribute all such submissions
              and Data in the performance of the Site and its services. We do
              not assert ownership rights over such submissions and Data, and
              subject to the rights you grant to us hereby in any and all such
              submissions, you retain full ownership of your submissions and
              Data and any intellectual property rights associated with your
              submissions; (e) you waive all creative and/or “moral rights” in
              the User Content which may be available to you in any part of the
              world, and confirm that no such rights have been or will be
              asserted; and (f) you appoint resume-perfect.com as your agent
              with full power to enter into any document and/or do any act
              resume-perfect.com may consider necessary or appropriate to
              confirm the grant and assignment, consent and waiver set out
              above;
            </p>

            <h3>10. Our Use of Content</h3>

            <p>
              resume-perfect.com will consider anything you provide to
              resume-perfect.com and/or contribute to this Site as available for
              our use free of any obligations to you (including any payment),
              except where solicited Requested Submissions are expressly
              governed by Additional Terms appearing elsewhere on this Site
              which event those Additional Terms may determine how we will treat
              your Requested Submissions. Under no circumstances will we pay you
              for the use of your ideas or submissions.
            </p>

            <h3>11. Disclaimer of Employment Opportunities</h3>

            <p>
              resume-perfect.com may from time to time provide employment
              posting data as to potential employment opportunities, or may use
              third parties and search engines to provide information about job
              potential employment opportunities, matching employment
              opportunities found on job searching sites maintained by other
              entities or persons, with your resume or Career Profile, job
              search requests submitted by you, and/or other usage data relating
              to your use of resume-perfect.com Applications. resume-perfect.com
              will generally attempt to match available data to ensure
              employment opportunities presented to you match the type of career
              opportunities desired by you, but resume-perfect.com disclaims any
              warranty or guarantee that you will receive interviews,
              communications or career opportunities or job alert leads you
              desire or that employment will follow from any such potential
              employment opportunity.
            </p>

            <h3>12. Deletion or Deactivation of User Content/Account</h3>

            <p>
              If you request that your User Content be deleted, your resume or
              profiles will no longer be we visible to users from
              resume-perfect.com Applications. If your User Content, including
              your personal information, was previously viewed by others using
              resume-perfect.com Applications, we cannot delete the information
              from their systems. When you deactivate your account or request
              that we delete your User Content on resume-perfect.com
              Applications, we will retain logs and non-personally identifiable
              information about you along with an archival copy of your
              information, which is not accessible by you or third parties on
              resume-perfect.com Applications but which might be used for
              recordkeeping and internal purposes.
            </p>

            <h3>13. Refund Policy</h3>

            <p>
              Customers who wish to redeem a refund must contact customer
              service by calling customer support
              <a href="tel:18882641699">1-888-264-1699 M-F</a> or emailing
              <a href="mailto:support@resume-perfect.com"
                >support@resume-perfect.com</a
              >. You acknowledge that any products or services that you purchase
              are subject to our Terms and any additional terms related to the
              provision of any product or service.
            </p>

            <h3>14. Responsible Use of This Site</h3>

            <p>
              resume-perfect.com's job posting and resume submission features
              are part of the services and may be used only by individuals
              seeking employment and/or career information. Certain
              resume-perfect.com services may only be used by employers seeking
              employees. In addition, resume-perfect.com may be used by
              individuals for permitted professional and career purposes as
              outlined in these Terms and the Privacy Policy.
            </p>

            <p>
              Please act responsibly when using this Site. You may only use this
              Site and its contents for lawful purposes and in accordance with
              applicable law and you are prohibited from storing, distributing
              or transmitting any unlawful material through this Site. You may
              not collect or store personal information regarding other users.
              You recognize that storing, distributing or transmitting unlawful
              material could expose you to criminal and/or civil liability.
            </p>

            <p>
              You agree that if a third-party claims that material you have
              contributed to the Site is posted in violation of an agreement not
              to so, or was posted unlawfully or is itself unlawful, you agree
              that you will remove it pending the resolution of the dispute,
              that you will bear the burden of establishing that it is permitted
              or lawful, and prior to posting that material again, you will
              provide necessary and reasonable assurances to us of your
              position.
            </p>

            <p>
              You understand and agree that all materials publicly posted or
              privately transmitted on or through this Site are the sole
              responsibility of the sender and poster, not resume-perfect.com,
              and that you are responsible for all material you upload, publicly
              post or otherwise transmit to or through this Site.
            </p>

            <h3>15. Your Release</h3>

            <p>
              If you have a dispute with one or more users (including
              merchants), you release the resume-perfect.com (defined in 4
              above) from claims, demands, causes of action, liabilities, costs
              or expenses and damages (actual and consequential of every kind
              and nature, known and unknown, arising out of or in any way
              connected with or arising out of such disputes. In agreeing to
              this release, you expressly waive any protections (whether
              statutory or otherwise) to the extent permitted by applicable law,
              that would otherwise limit the coverage of this release to include
              only those claims which you may know or suspect to exist in your
              favor at the time of agreeing to this release.
            </p>

            <h3>16. Indemnification</h3>

            <p>
              You agree to indemnify and hold harmless resume-perfect.com and
              its officers, directors, employees, agents, distributors and
              affiliates from and against any and all claims, demands,
              liabilities, costs or expenses, including reasonable attorney's
              fees, resulting from your breach of these Terms, including any of
              the foregoing provisions, representations or warranties, and/or
              from your placement or transmission of any content onto
              resume-perfect.com 's servers, and/or from any and all use of your
              account.
            </p>

            <h3>17. Prohibited Abusive Conduct</h3>

            <p>
              Without limitation, and in addition to all terms contained in
              these Terms, you agree not to send, create or reply to emails
              attaching or containing or sending copies of a single message to
              numerous or multiple users, or send large or multiple files or
              messages to a single user with malicious intent, or engage in
              unsolicited emailing to others for business, fund raising,
              political or other purposes. You also agree not to undertake any
              other activity which may adversely affect the operation, function,
              or intended use of this Site by any other person.
            </p>

            <h3>18. Your Security</h3>

            <p>
              Use common sense and prudence while using all internet access and
              this Site. User names and passwords for this Site are for
              individual use only. You are responsible for the creation and
              security of your username and password (if any).
              resume-perfect.com is entitled to monitor your username and
              password and, at its discretion, require you to change it. We may
              do that if we believe it is inadequate or that it is being used by
              others, although we do not guarantee or warrant that we can or
              will detect such use and disclaim any damages alleged to have
              resulted from any such unauthorized use of your User name and or
              password. Without limiting the foregoing disclaimer of
              responsibility and damages, if you use a username and password
              that resume-perfect.com considers insecure, resume-perfect.com
              will be entitled to require this to be changed and/or suspend or
              terminate your account until proper security measures are adopted.
            </p>

            <h3>19. Violation of Security Systems</h3>

            <p>
              You are prohibited from using any services or facilities provided
              in connection with this Site to compromise security or tamper with
              Site and system resources and/or accounts. Using devices, software
              or tools to compromise security (such as password guessing
              programs, cracking tools, or network probing tools) is prohibited.
              If you become involved in any perceived violation of system
              security, resume-perfect.com reserves the right to release details
              to system administrators at other sites in order to assist them in
              resolving security incidents.
            </p>

            <h3>20. Investigations</h3>

            <p>
              resume-perfect.com will cooperate with law enforcement where it
              deems such cooperation necessary, appropriate or where required to
              do so by law, court order or exigent circumstances. It reserves
              the right to investigate suspected violations of these Terms,
              including without limitation any violation arising from
              submissions, postings or emails you make, send or cause to be sent
              to any Forum. resume-perfect.com may seek to gather information
              from the user who is suspected of violating these Terms, and from
              any other user involved or suspected to be involved in such
              misuse.
            </p>

            <p>
              resume-perfect.com may suspend any users whose conduct or postings
              are under investigation, and may remove such material from its
              servers as it deems appropriate and without notice. If
              resume-perfect.com believes, in its sole discretion, that a
              violation of these Terms has occurred, it may edit or modify any
              submission, posting or e-mails, remove the material permanently,
              cancel postings, warn or suspend users and passwords, terminate
              accounts or take other protective and corrective action it deems
              appropriate in its sole discretion.
            </p>

            <p>
              resume-perfect.com will fully cooperate with any law enforcement
              authorities or court order requesting or directing
              resume-perfect.com to disclose the identity of anyone posting
              emails, or publishing or otherwise making available any materials
              that are believed to violate these Terms or other applicable law.
            </p>

            <p>
              BY ACCEPTING THIS AGREEMENT, YOU WAIVE AND HOLD HARMLESS
              resume-perfect.com FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN
              BY resume-perfect.com DURING OR AS A RESULT OF ITS INVESTIGATIONS,
              AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS
              BY EITHER resume-perfect.com OR LAW ENFORCEMENT AUTHORITIES
              PURSUANT TO A LAWFUL PROCESS OR OTHER ORDER.
            </p>

            <h3>
              21. Service Access, Fees of Other Providers & Proprietary Online
              Service Rules
            </h3>

            <p>
              To use this Site, you must obtain access to the World Wide Web and
              pay any service fee associated with that access. You will need to
              provide all equipment necessary to connect to the Site on the
              World Wide Web (including a computer, modem, mobile device, tablet
              and/or other access devices). You are solely responsible for the
              fees paid by you to internet service providers, and the
              functionality of the equipment and devices you use.
            </p>

            <p>
              Areas of this Site accessed by or through a proprietary online
              service are subject to the rules, policies and guidelines of such
              proprietary online service. Be sure to verify that your Site
              access complies with any applicable rules, policies and
              procedures.
            </p>

            <h3>22. Reservation of Rights</h3>

            <p>
              resume-perfect.com reserves the right to modify or discontinue,
              temporarily or permanently, all or any part of this Site and/or
              any software, facilities and services on this Site, with or
              without notice, and/or to establish general guidelines and
              limitations on their use. Without limitation, you agree that in
              addition to business decisions, litigation, legal or financial
              requirements, and other business reasons and decisions in our sole
              discretion, power outages, national emergencies, states of war,
              force majeure, and other causes beyond our control may cause such
              suspensions, or the discontinuance of service.
            </p>

            <h3>23. Non-Party Sites</h3>

            <p>
              Use caution before providing any sensitive information (examples:
              social security numbers, driver’s license number, residence
              address, or financial information) to any Site including sites
              linked by this Site. Those other sites may contain information
              that some people may find annoying, inappropriate or offensive.
              Those other sites are not investigated, monitored, checked for
              accuracy, appropriateness, or completeness, and are not controlled
              or managed by resume-perfect.com. You acknowledge that (whether or
              not such sites are affiliated in any way with resume-perfect.com),
              that resume-perfect.com is not responsible for the content,
              accuracy, offensiveness, decency, opinions, posted comments and
              responses, data gathering, retention and use practices, privacy
              practices, policies or procedures of such Non-Party sites
              including their job postings, salary information, job
              requirements, employer data, legality, decency, or any other
              aspect of the content of such sites.
            </p>

            <p>
              This Site’s inclusion of a link does not represent or imply an
              endorsement of any site by resume-perfect.com or any association
              with its operators or a verification of the Data they present.
              resume-perfect.com cannot make any representations or warranties
              as to the security of any information (including, without
              limitation, credit card and other personal information) you might
              be requested to give any other party, and we disclaim all such
              representations and warranties, and you irrevocably waive any
              claim against us with respect to such sites. Purchases you make on
              such Non-Party sites are exclusively between you and the Non-Party
              site.
            </p>

            <p>
              Cautiously assess requests for sensitive information (e.g.,
              residence address, social security numbers or financial
              information) made by any Non-Party site and make whatever
              investigation you feel necessary and appropriate before
              proceeding.
            </p>

            <h3>24. Jurisdictional Issues</h3>

            <p>
              The Materials and all other content in this Site are presented for
              the purpose of providing information regarding products and
              services available to Users who actively seek access to and use of
              this Site. No consent to jurisdiction in any country, state or
              territory is recognized.
            </p>

            <h3>25. Payments, Subscriptions, Term & Termination</h3>

            <p>
              Your credit card will show a $2.45 charge or $10.45, whichever
              plan you choose.
            </p>

            <p>
              If you purchase any services that we offer for a fee, either on a
              one-time or subscription basis, you agree that
              resume-perfect.com's third party vendors may store your payment
              information. You also agree to pay the applicable fees for
              products/services you order as they become due plus all related
              taxes, and to reimburse us for all collection costs and interest
              for any overdue amounts. Failure to pay may result in the
              suspension or termination of your subscription with no additional
              notice to you. Depending on where you transact with us, the type
              of payment method used and where your payment method was issued,
              your transaction may be subject to foreign exchange fees or
              differences in prices, because of exchange rates. Your transaction
              may be subject to foreign currency exchange fees assessed by your
              bank or card issuer.
            </p>

            <p>
              resume-perfect.com is not responsible for any foreign currency
              exchange fees or surcharges and will not make any compensation or
              reimbursement for charges imposed by your bank or card issuer.
              resume-perfect.com does not support all payment methods,
              currencies or locations for payment. If the payment method you use
              with us, such as a credit card, reaches its expiration date and
              you do not edit your payment method information or cancel your
              account or such service, you authorize us to continue billing that
              payment method and you remain responsible for any uncollected
              amounts. Your obligation to pay fees continues through the end of
              the subscription period during which you cancel your subscription.
              All applicable taxes are calculated based on the billing
              information you provide us at the time of purchase.
            </p>

            <p>
              Your obligation to pay fees continues through the end of the
              subscription period during which you cancel your subscription. You
              may cancel or suspend your services by sending us an email at
              Resume-Perfect does not guarantee refunds. Your cancellation does
              not suspend or terminate the continued application of the Terms
              pertinent to Site security and operation, which include but are
              not limited to the description of prohibited practices, and the
              any licenses you gave granted on material and Data submitted to
              this Site.
            </p>

            <p>
              If you purchase any services from us for a fee, either on a
              one-time or subscription basis, you agree that
              resume-perfect.com's third party vendors may store your payment
              information. You also agree to pay the applicable fees for
              products/services you order as they become due, plus all related
              taxes, and to reimburse us for all collection costs and interest
              for any overdue amounts. Failure to pay may result in the
              termination of your subscription along with loss of any stored
              Data. Depending on where you transact with us, the type of payment
              method used and where your payment method was issued, your
              transaction may be subject to foreign exchange fees or price
              differentials due to exchange rates. Your bank or card issuer may
              also impose fees or costs. resume-perfect.com is not responsible
              for and does not reimburse for foreign currency exchange fees or
              surcharges or charges imposed by your bank or card issuer.
            </p>

            <p>
              resume-perfect.com does not support all payment methods,
              currencies or locations. If your payment method such as a credit
              card, reaches its expiration date and you do not edit your payment
              method information or cancel your account or such service, you
              authorize us to continue billing that payment method, and you
              remain responsible for any uncollected amounts. Your obligation to
              pay fees continues through the end of the subscription period
              during which you cancel your subscription. All applicable taxes
              are calculated based on the billing information you provide us at
              the time of purchase. In the event that your payment fails, we may
              attempt to charge your credit card for a discounted amount in
              order to retain your membership.
            </p>

            <h3>26. Notice for California Users.</h3>

            <p>
              Under California Civil Code Section 1789.3, California users of
              the Site are entitled to the following specific consumer rights
              notice: The Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs may be
              contacted in writing at 400 R Street, Suite 1080, Sacramento,
              California 95814, or by telephone at (916) 445-1254 or (800)
              952-5210.
            </p>

            <h3>27. How To Contact Us</h3>
            This Site is controlled and operated by CompuData, LLC <br />
            Please forward any comments, questions or complaints about the Site
            to
            <a href="mailto:support@resume-perfect.com"
              >support@resume-perfect.com</a
            >. <br />
            Please forward any questions regarding privacy & legal matters to
            <a href="mailto:support@resume-perfect.com"
              >support@resume-perfect.com</a
            >.

            <h4>Written correspondence may be sent to:</h4>
            <p>
              CompuData, LLC <br />
              2620 South Maryland Pkwy, Suite 14#265 <br />
              Las Vegas, Nevada 89109 <br />
              USA
            </p>

            <h3>28. General</h3>

            <p>
              If any provision of these Terms shall be unlawful, void, or for
              any reason unenforceable, then that provision shall be deemed
              severed from this agreement and shall not affect the validity and
              enforceability of any remaining Terms and conditions. This is the
              entire agreement between you and resume-perfect.com relating to
              the matters contained here and the Site, and it may not be altered
              orally.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mixpanelMixin } from "@/mixins/MixPanelMixin";

export default {
  name: "Terms and Conditions",
  mixins: [mixpanelMixin],

  created() {
    this.mixpanelTrack("V2-TOS");
  },
};
</script>

<style lang="scss" scoped>
section.typical-page-section {
  padding-top: 40px;
  padding-bottom: 100px;
}

.typical-title {
  text-align: center;
  margin-bottom: 24px;
}

.typical-content {
  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  p,
  h2,
  h3,
  h4 {
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 40px;
  }

  h4 {
    margin-top: 20px;
  }

  a {
    color: $primary;
  }
}
</style>
